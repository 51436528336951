import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import React from 'react';
import { ProtectedRoute } from '../contexts/protectedRoute';
import Menu from '../components/menu';
import Footer from '../components/footer';
import NotFoundPage from '../pages/notFound';
import HomePage from '../pages/home';
import LoginPage from '../pages/login';
import BlogPage from '../pages/blog';
import PostBlogPage from '../pages/blog/blogPage';
import SignUpPage from '../pages/signup';
import { AuthProvider } from '../contexts/authContext';
import { Roles } from '../api/default';
import ProfilePage from '../pages/profile';
import SearchPage from '../pages/search';
import ClientSchedulesPage from '../pages/clientSchedules';
import PartnerPage from '../pages/partner';
import RecoverPasswordPage from '../pages/recoverPassword';
import ChangePasswordPage from '../pages/changePassword';


const AppLayout = () => (
    <>
        <Menu />
        <Outlet />
        <Footer />
    </>
);

export default function DefaultRoutes() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route element={<AppLayout />}>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/login' element={<LoginPage />} />
                        <Route path='/signup' element={<SignUpPage />} />
                        <Route path='/recoverPassword' element={<RecoverPasswordPage />} />
                        <Route path='/blog' element={<BlogPage />} />
                        <Route path='/blog/post/:id' element={<PostBlogPage />} />
                        <Route path='/pesquisa' element={<SearchPage />} />
                        <Route path='/parceiroGuru/:partner_id' element={<PartnerPage />} />
                        <Route path='/meuPerfil' element={<ProtectedRoute roles={[Roles.CLIENTE_GURU, Roles.PARCEIRO_A_GURU]}> <ProfilePage /> </ProtectedRoute>} />
                        <Route path='/changePassword' element={<ProtectedRoute roles={[Roles.CLIENTE_GURU, Roles.PARCEIRO_A_GURU]}> <ChangePasswordPage /> </ProtectedRoute>} />
                        <Route path='/meusAgendamentos' element={<ProtectedRoute roles={[Roles.CLIENTE_GURU, Roles.PARCEIRO_A_GURU]}> <ClientSchedulesPage /> </ProtectedRoute>} />
                    </Route>
                    <Route path='*' element={<NotFoundPage />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}
