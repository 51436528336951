import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styles from "./style.module.scss"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SectionSearchHome from '../../sections/sectionSearchHome';
import { AiOutlineExclamationCircle } from "react-icons/ai";

export default function HomePage() {
  
  const handleWhatsapp = () => {
    const whatsappUrl = `https://chat.whatsapp.com/L1vnPm1OUxO9VQ0r41czTB `;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <HelmetProvider>
      <section id={styles.HomePage}>
        <Helmet>
          <title>Home - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>
        <SectionSearchHome />
        <Container className={styles.container}>
          <Row className={styles.rowIcon}>
            <AiOutlineExclamationCircle  className={styles.icon}/>
          </Row>
          <Row>
            <h2 className={styles.title}>Estamos em processo de desenvolvimento!</h2>
            <p className={styles.text}>
              Nosso site está atualmente em construção. Em breve, teremos muitas novidades para você!
            </p>
            <p className={styles.subText}>Participe no nosso Pré-lançamento e entre no grupo.</p>
          </Row>
          <Row className={styles.rowButton}>
            <Button className={styles.button} onClick={handleWhatsapp}>
              Entrar no Grupo
            </Button>
          </Row>
        </Container>
      </section>
    </HelmetProvider>
  );
}
