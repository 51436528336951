// export const BASE_URL = 'http://127.0.0.1:8000';
export const BASE_URL = 'https://api.goinguru.com';

export const AUTH_DEBUG = false;

/*
  HttpStatus e HttpResponse são utilizados em várias partes
  do projeto, ao altera-ĺos verificar se as demais partes continuaram funcionando!
*/
export const HttpStatus = {
  OK: 200,
  ERROR: 400
}

export class HttpResponse {
  status = HttpStatus.ERROR;
  data = null;

  constructor(status, data) {
    this.status = status; 
    this.data = data;
  }
}

export const Roles = {
  CLIENTE_GURU: 1,
  PARCEIRO_B_GURU: 2,
  PARCEIRO_A_GURU: 3,
  BLOGGER_GURU: 4,
  GERENTE_GURU: 5,
  ADMIN_GURU: 6,
}
