import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Button, Form, Spinner, Alert } from 'react-bootstrap';
import styles from "./style.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BiSearch } from 'react-icons/bi';
import SectionSearchPartner from '../../sections/sectionSearchPartner';
import { useCategoriesResumeHomeData, useServicesAndPartnerSearchData } from '../../api/homeAPI/homeClient';
import Pagination from '../../components/pagination';


const citiesOptions = [
    { value: 'são-paulo', label: 'São Paulo' },
    { value: 'rio-de-janeiro', label: 'Rio de Janeiro' },
    { value: 'belo-horizonte', label: 'Belo Horizonte' },
    { value: 'porto-alegre', label: 'Porto Alegre' },
    { value: 'curitiba', label: 'Curitiba' },
    { value: 'brasília', label: 'Brasília' },
    { value: 'salvador', label: 'Salvador' },
    { value: 'fortaleza', label: 'Fortaleza' },
    { value: 'manaus', label: 'Manaus' },
    { value: 'recife', label: 'Recife' }
];


const customStyles = {
    control: (provided, state) => ({
        ...provided,
        marginTop: '5px',
        borderColor: '#EE880C',
        boxShadow: state.isFocused ? '0 0 5px 2px #EE880C' : provided.boxShadow,
        '&:hover': {
            borderColor: '#EE880C'
        }
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        '&:hover': {
            color: '#EE880C'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#390040' : state.isFocused ? '#EE880C' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EE880C',
            color: '#fff'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#390040',
    }),
};

export default function SearchPage() {
    const [serviceName, setServiceName] = useState('');
    const [selectedTags, setSelectedTags] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedType, setSelectedType] = useState(false);
    const [searchType, setSearchType] = useState(null);
    const [searchTypeControll, setSearchTypeControll] = useState(null);
    const [searchApproved, setSearchApproved] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [pagePagination, setPagePagination] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10; 

    const { data: serviceSearchData } = useCategoriesResumeHomeData();
    const { data: servicesAndPartnerData, isError, isLoading } = useServicesAndPartnerSearchData(serviceName, selectedTags ? selectedTags.label : null, selectedCity ? selectedCity.label : null, selectedType, pagePagination, searchApproved )

    const TagsOptions = useMemo(() => 
        serviceSearchData?.services?.map(service => ({
            value: service.id, 
            label: service.name
        })) || [], 
        [serviceSearchData]
    );

    useEffect(() => {
        if (servicesAndPartnerData) {
            setTotalPages(Math.ceil(servicesAndPartnerData.count / itemsPerPage))

        }
    }, [servicesAndPartnerData]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        const serv = searchParams.get('serv');
        const tags = searchParams.get('tags');
        const loc = searchParams.get('loc');
        const type = searchParams.get('type');

        if (serv) {
            setServiceName(serv);
            if(serv.length > 0){
                setSearchApproved(true);
            }
        }

        if (tags) {
            const professional = TagsOptions.find(option => option.label === tags);
            if (professional) {
                setSelectedTags(professional);
                setSearchApproved(true);
            }
        }

        if (loc) {
            const city = citiesOptions.find(option => option.label === loc);
            setSelectedCity(city);
        }

        if (type) {
            setSearchType(type);
            if(type === "local"){
                setSelectedType(false);
                setSearchTypeControll("local");
            }
            if(type === "online"){
                setSearchTypeControll("Online");
                setSelectedType(true);
            }
        }
    }, [location.search, TagsOptions, setSearchApproved]);

    const handleSearch = () => {
        if ((selectedTags || serviceName) && selectedCity && searchType) {
            const tag = selectedTags ? selectedTags.label : serviceName;
            const city = selectedCity.label;
            window.location.href = `/pesquisa?serv=${serviceName}&tags=${tag}&loc=${city}&type=${searchType}`;
        } else {
            toast.error('Por favor, informe ou selecione um serviço e cidade!');
        }
    };

    const handlePageChange = (page) => {
        setPagePagination(page);
    };

    if (isLoading ) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    if (isError) {
        return (
            <section id={styles.SectionCompanyGuru}>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="danger">
                                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    return (
        <HelmetProvider>
            <section id={styles.SearchPage}>
                <Helmet>
                    <title>Pesquisa - GoinGuru</title>
                    <meta name="description" content="GoinGuru" />
                </Helmet>
                <Container>
                    <Row className={styles.rowSearchType}>
                        <Col xs={6} sm={6} md={6} lg={2} xl={2}>
                            <Button
                                onClick={() => setSearchType('local')}
                                className={`${styles.buttonType} ${searchType === 'local' ? styles.buttonTypeSelected : ''}`}
                            >
                                No Local
                            </Button>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={2} xl={2}>
                            <Button
                                onClick={() => setSearchType('online')}
                                className={`${styles.buttonType} ${searchType === 'online' ? styles.buttonTypeSelected : ''}`}
                            >
                                Online
                            </Button>
                        </Col>
                    </Row>
                    <Row className={styles.rowSearch}>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Form.Control
                                type="text"
                                placeholder="Qual serviço você tá procurando?"
                                value={serviceName}
                                onChange={(e) => setServiceName(e.target.value)}
                                className={styles.formLabel}
                                maxLength={55}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Select
                                options={TagsOptions}
                                value={selectedTags}
                                onChange={setSelectedTags}
                                placeholder="Categorias do serviço"
                                styles={customStyles}
                                isClearable
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                            <Select
                                options={citiesOptions}
                                value={selectedCity}
                                onChange={setSelectedCity}
                                placeholder="Cidade"
                                styles={customStyles}
                                isClearable
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                            <Button onClick={handleSearch} className={styles.buttonSearch}><BiSearch className={styles.icon}/> Pesquisar</Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id={styles.SectionSearch}>
                {servicesAndPartnerData && servicesAndPartnerData.count > 0 ?(
                    <Row>
                        <SectionSearchPartner servicesAndPartnerData={servicesAndPartnerData || []} typeSearch={searchTypeControll}/>
                        <Pagination totalPages={totalPages} onPageChange={handlePageChange}/>
                    </Row>
                ):(
                    <p className={styles.titleNoSearch}> Não foi encontrado serviços, altere as informações da pesquisa e tente novamente!</p>
                )}
            </section>
        </HelmetProvider>
    );
}
