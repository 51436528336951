import { HttpResponse, HttpStatus, BASE_URL, AUTH_DEBUG } from "./default";

const postRecover = async (email) => {
    const url = `${BASE_URL}/generate-password/`
    var errorMessage;
    try {
        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({ email })
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("RecoverPasswordAPI::postRecover(): ", data.token);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on postRecover()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const postChangePassword = async (oldPassword, newPassword, token) => {
    const url = `${BASE_URL}/change-password/`
    var errorMessage;
    try {
        const options = {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'jwt': token,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({ old_password: oldPassword, new_password: newPassword })
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("RecoverPasswordAPI::postRecover(): ", data.token);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on postRecover()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

export const PasswordAPI = {
    postRecover,
    postChangePassword,
}