import { useQuery, useMutation } from "@tanstack/react-query";
import { BASE_URL, AUTH_DEBUG } from "../default";

const getBlogResume = async (page) => {
    const url = `${BASE_URL}/blog/listPosts/?page=${page}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("BlogAPI::getBlogResume(): ", data);
        return data;
    } else {
        throw new Error("Error on getBlogResume()");
    }
};

export function useBlogResumeData(page) {
    const query = useQuery({
        queryKey: ['blogResume', page],
        queryFn: () => getBlogResume(page),
        staleTime: 1000 * 60 * 1,
        cacheTime: 1000 * 60 * 1,
        enabled: !!page,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getBlogBanner = async () => {
    const url = `${BASE_URL}/blog/listBannerBlog`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("BlogAPI::getBlogBanner(): ", data);
        return data;
    } else {
        throw new Error("Error on getBlogBanner()");
    }
};

export function useBlogBannersData() {
    const query = useQuery({
        queryKey: ['blogBanners'],
        queryFn: () => getBlogBanner(),
        staleTime: 1000 * 60 * 1,
        cacheTime: 1000 * 60 * 2,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getOnePostBlog = async (idPost) => {
    const url = `${BASE_URL}/blog/listPosts/${idPost}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("BlogAPI::getOnePostBlog(): ", data);
        return data;
    } else {
        throw new Error("Error on getOnePostBlog()");
    }
};

export function useOnePostData(idPost) {
    const query = useQuery({
        queryKey: ['blogPost', idPost],
        queryFn: () => getOnePostBlog(idPost),
        enabled: !!idPost,
        staleTime: 1000 * 60 * 1,
        cacheTime: 1000 * 60 * 2,
    });

    return {
        ...query,
        data: query.data,
    };
}

const getAllEmailNewsLetterBlog = async (token, page) => {
    const url = `${BASE_URL}/blog/newsLetter/admin/?page=${page}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("BlogAPI::getAllEmailNewsLetterBlog(): ", data);
        return data;
    } else {
        throw new Error("Error on getAllEmailNewsLetterBlog()");
    }
};

export function useAllEmailNewsLetterData(token, page) {
    const query = useQuery({
        queryKey: ['blogNewsLetter', token, page],
        queryFn: () => getAllEmailNewsLetterBlog(token, page),
        enabled: !!token && !!page, 
        staleTime: 1000 * 60 * 1,
        cacheTime: 1000 * 60 * 2,
    });

    return {
        ...query,
        data: query.data,
    };
}


const postNewsletter = async ({ email }) => {
    const url = `${BASE_URL}/blog/newsLetter/`;

    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ email: email, is_active: true }),
    };

    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};


export function usePostNewsletter( email ) {
    const mutate = useMutation({
        mutationFn: postNewsletter,
        mutationKey: { email },
        enabled: [ !!email ],
    });

    return mutate;
}