import React from 'react';
import { Container, Row } from 'react-bootstrap';
import SectionLogin from '../../sections/sectionLogin';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function LoginPage() {
  return (
    <HelmetProvider>
      <Container>
        <Helmet>
            <title>Login - Blog</title>
        </Helmet>
        <Row>
          <SectionLogin />
        </Row>
      </Container>
    </HelmetProvider>
  );
}
