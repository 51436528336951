import { HttpResponse, HttpStatus, BASE_URL, AUTH_DEBUG } from "./default";

const signup = async (name, email, password, birth, cpf) => {
    const url = `${BASE_URL}/createUser/`
    var errorMessage;
    try {
        const options = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({ name, email, password, cpf, birth, role: [1] })
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("SignupAPI::signup(): ", data.token);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on Signup()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

export const SignupAPI = {
    signup,
}