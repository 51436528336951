import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SectionSignUp from '../../sections/sectionSignUp';

export default function SignUpPage() {
  return (
    <HelmetProvider>
      <Container>
        <Helmet>
            <title>Cadastro - GoinGuru</title>
        </Helmet>
        <Row>
          <SectionSignUp />
        </Row>
      </Container>
    </HelmetProvider>
  );
}
