import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Dropdown, Modal, Button, Alert } from 'react-bootstrap';
import styles from "./style.module.scss";
import NoImage from '../../assets/no-image.jpg';
import { BiSolidStar, BiGroup, BiClipboard, BiListCheck, BiMapPin } from 'react-icons/bi';
import { format, setDefaultOptions, addMinutes } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import DatePaginationPartner from '../../components/datePaginationPartner';
import { toast } from 'react-toastify';
import { SearchAPI } from '../../api/homeAPI/homeClient';
import { useAuthContext } from '../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';

setDefaultOptions({ locale: ptBR });

const formatDuration = (duration) => {
    if (duration) {
        return duration.substring(0, 5);
    }
    return null;
};

export default function SectionSearchPartner({ servicesAndPartnerData, typeSearch }) {
    const { token, user } = useAuthContext();
    const navigate = useNavigate();
    const [serviceData, setServiceData] = useState({});
    const [selectedSchedule, setSelectedSchedule] = useState({
        service: null,
        partnerId: null,
        time: null,
        employee: null,
        date: null
    });
    const [showModal, setShowModal] = useState(false);

    const handleServiceSelect = (service, partnerId) => {
        setServiceData(prevData => ({
            ...prevData,
            [partnerId]: {
                ...prevData[partnerId],
                selectedService: service,
            }
        }));
    };
    
    const handleTimeSelect = (selection, partnerId) => {
        const selectedService = serviceData[partnerId]?.selectedService;
    
        setServiceData(prevData => ({
            ...prevData,
            [partnerId]: {
                ...prevData[partnerId],
                selectedService: selectedService,
                availableEmployees: selection.availableEmployees,
                date: selection.date,
                time: selection.time
            }
        }));
    };

    const handleEmployeeSelect = (employee, partnerId) => {
        setSelectedSchedule(prevSchedule => ({
            ...prevSchedule,
            employee: employee,
            partnerId: partnerId,
            service: serviceData[partnerId]?.selectedService || prevSchedule.service,
            date: serviceData[partnerId]?.date || prevSchedule.date,
            time: serviceData[partnerId]?.time || prevSchedule.time,
        }));
        console.log('teste',serviceData[partnerId])
    };
    
    useEffect(() => {
        const { service, date, time, employee } = selectedSchedule;

        if (service && date && time && employee) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [selectedSchedule]);

    useEffect(() => {
        if (servicesAndPartnerData) {
            servicesAndPartnerData?.results?.forEach(servicePartner => {
                if (servicePartner.services_provided.length > 0 && !serviceData[servicePartner.id]?.selectedService) {
                    handleServiceSelect(servicePartner.services_provided[0], servicePartner.id);
                }
            });
        }
    }, [servicesAndPartnerData, serviceData]);

    const handleCloseModal = () => setShowModal(false);

    const handleFormSubmitSchedule = async () => {
        if (!user || !user.id) {
            toast.error('Usuário não está autenticado. Por favor, faça o login e tente novamente!');
            return;
        }
    
        const { service, date, time, employee, partnerId } = selectedSchedule;
    
        if (!service || !date || !time || !employee || !partnerId) {
            toast.error('Dados incompletos para o agendamento. Por favor, preencha todos os campos!');
            return;
        }

        const formattedDate = format(new Date(date), 'yyyy-MM-dd');
        const startDateTime = `${formattedDate}T${time}:00`;

        const [durationHours, durationMinutes] = formatDuration(service?.duration)?.split(':').map(Number) || [0, 0];
        const endDateTime = addMinutes(new Date(startDateTime), durationHours * 60 + durationMinutes);
        const formattedEndDateTime = format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss");

        const formData = new FormData();
        formData.append('partner', partnerId);
        formData.append('client', user.id);
        formData.append('employee', employee?.id);
        formData.append('service', service?.id);
        formData.append('start_time', startDateTime);
        formData.append('end_time', formattedEndDateTime);
        formData.append('status', 'Agendado');

        try {
            const response = await SearchAPI.postSchedule(formData, token);
    
            if (response.status === 200) {
                toast.success('Agendamento realizado com sucesso!');
                navigate('/meusAgendamentos');
            } else if (response.status === 400 && token) {
                toast.error('Erro ao fazer agendamento, horário indesponível, por favor atulizar a página!');
            } else if (response.status === 404) {
                toast.error('Erro ao fazer agendamento, verifique os dados novamente!');
            } else if (response.status === 400 && !token) {
                toast.error('Erro ao fazer agendamento, realize o login e tente novamente!');
            }
        } catch (error) {
            console.error('Erro ao fazer agendamento:', error.message);
            toast.error('Erro ao fazer agendamento, verifique os dados e tente novamente!');
        }

        setSelectedSchedule({
            service: null,
            partnerId: null,
            time: null,
            employee: null,
            date: null
        });
    };


    return (
        <section id={styles.SectionSearchPartner}>
            <Container>
                {servicesAndPartnerData?.results && servicesAndPartnerData?.results.length > 0 && servicesAndPartnerData?.results.map(servicePartner => (
                    <Row className={styles.rowPartner} key={servicePartner.id}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={5} className={styles.colInfoPartner}>
                            <Row>
                                <Col xs={4} sm={3} md={3} lg={3} xl={3} className={styles.colLogoPartner}>
                                    <Image className={styles.logoPartner} src={servicePartner.photo || NoImage} />
                                </Col>
                                <Col xs={8} sm={9} md={9} lg={9} xl={9}>
                                    <Link to={`/parceiroGuru/${servicePartner.id}`} className={styles.linkPartner}>
                                        <p className={styles.titlePartner}>{servicePartner.name}</p>
                                    </Link>
                                    <p className={styles.titleMapPartner}><BiMapPin className={styles.icon}/> Endereço</p>
                                    <p className={styles.textPartner}>{servicePartner.a_street_name}, Nº {servicePartner.a_number} - {servicePartner.a_neighborhood}, {servicePartner.a_city}/{servicePartner.a_state} - {servicePartner.a_postal_code}</p>
                                    {servicePartner.a_complement && (
                                        <p className={styles.textPartner}>{servicePartner.a_complement}</p>
                                    )}
                                </Col>
                            </Row>
                            <Row className={styles.rowTextsPartner}>
                                <p className={styles.textPartner}>{servicePartner.about}</p>
                                <div className={styles.servicesList}>
                                    <p className={styles.titleCatPartner}><BiListCheck className={styles.icon}/> Categorias</p>
                                    <p className={styles.textPartner}>
                                        {servicePartner.services_partners.map((service, index) => (
                                            <span key={index}>
                                                {service.name}{index < servicePartner.services_partners.length - 1 ? ', ' : ''}
                                            </span>
                                        ))}
                                    </p>
                                </div>
                                <p className={styles.textPartner}><BiSolidStar className={styles.iconStar} />45 Avaliações</p>
                            </Row>
                        </Col>
                        {servicePartner.services_provided && servicePartner.services_provided.length > 0 ? (
                            <Col xs={12} sm={12} md={12} lg={12} xl={7} className={styles.colInfoSchedules}>
                                <Row className={styles.rowSchedules}>
                                    <p className={styles.titleSchedules}><BiClipboard className={styles.icon}/> Serviços Disponíveis</p>
                                    <Dropdown>
                                        <Dropdown.Toggle id={`dropdown-services-${servicePartner.id}`} className={styles.dropdownSchedules}>
                                            {serviceData[servicePartner.id] ? serviceData[servicePartner.id].selectedService.name : 'Selecionar Serviço'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className={styles.dropdownSchedulesMenu}>
                                            {servicePartner.services_provided.map(service => (
                                                <Dropdown.Item
                                                    key={service.id}
                                                    onClick={() => handleServiceSelect(service, servicePartner.id)}
                                                >
                                                    {service.name} - R${service.price}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Row>
                                {serviceData[servicePartner.id]?.selectedService && (
                                    <>
                                        <DatePaginationPartner
                                            partner_id={servicePartner.id}
                                            selectedService={serviceData[servicePartner.id]?.selectedService}
                                            partnerData={servicePartner.working_hours}
                                            duration={serviceData[servicePartner.id]?.selectedService ? formatDuration(serviceData[servicePartner.id].selectedService.duration) : null}
                                            onTimeSelect={(selection) => handleTimeSelect(selection, servicePartner.id)}
                                        />
                                        {serviceData[servicePartner.id]?.availableEmployees && serviceData[servicePartner.id].availableEmployees.length > 0 && (
                                            <Row className={styles.rowProfPartner}>
                                                <p className={styles.titleProfPartner}><BiGroup className={styles.icon}/> Profissionais</p>
                                                {serviceData[servicePartner.id].availableEmployees.map((employee) => (
                                                    <Col 
                                                        key={employee.id} 
                                                        xs={4} sm={3} md={3} lg={2} xl={2} 
                                                        className={styles.colProfPartner}
                                                        onClick={() => handleEmployeeSelect(employee, servicePartner.id)}
                                                    >
                                                        <Image className={styles.photoProfPartner} src={employee.photo} />
                                                        <p className={styles.nameProfPartner}>{employee.name}</p>
                                                    </Col>
                                                ))}
                                            </Row>
                                        )}
                                    </>
                                )}
                            </Col>
                        ) : (
                            <Col className={styles.colNoInfoSchedules}>
                                <p className={styles.textNoInfo}>
                                    O ParceiroGuru oferece o serviço {typeSearch === 'local' ? 'Online' : typeSearch === 'Online' ? 'No Local' : 'relacionado ao pesquisado, entre em seu perfil e descubra mais'}
                                    {!serviceData[servicePartner.id]?.selectedService ?  ' ou tem serviços relacionados ao pesquisado, entre em seu perfil e descubra mais!': ''}
                                    <br/>
                                    <br/>Agradeçemos a consulta!
                                </p>
                            </Col>
                        )
                    }
                    </Row>
                ))}
            </Container>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered className={styles.modal}>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.title}>Agendamento do serviço</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4} className={styles.colLogoService}>
                            <Image className={styles.logoService} src={selectedSchedule.service?.photo || NoImage} />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <p className={styles.text}><strong>Serviço:</strong> {selectedSchedule.service?.name}</p>
                            <p className={styles.text}><strong>Descrição:</strong> {selectedSchedule.service?.description}</p>
                            {!selectedSchedule.service?.is_custom_quote ? (
                                <p className={styles.text}><strong>Preço:</strong> R$ {selectedSchedule.service?.price}</p>
                            ) : (
                                <p className={styles.text}><strong>Preço:</strong> A combinar</p>
                            )}
                            {!selectedSchedule.service?.is_online_service ? (
                                <p className={styles.text}><strong>Atendimento:</strong> No local</p>
                            ) : (
                                <p className={styles.text}><strong>Atendimento:</strong> Online</p>
                            )}
                            <p className={styles.text}>
                                <strong>Data:</strong> {selectedSchedule.date ? format(new Date(selectedSchedule.date), 'dd/MM/yyyy') : ''}
                            </p>
                            <p className={styles.text}><strong>Horário:</strong> {selectedSchedule.time}</p>
                            <p className={styles.text}><strong>Duração:</strong> {formatDuration(selectedSchedule.service?.duration)}</p>
                            <p className={styles.text}><strong>Profissional:</strong> {selectedSchedule.employee ? selectedSchedule.employee.name : 'Nenhum profissional disponível'}</p>
                        </Col>
                    </Row>
                    <Row className={styles.rowButton}>
                        <Col className={styles.colButtonCancel}>
                            <Button className={styles.buttonCancel} onClick={handleCloseModal}>
                                Cancelar
                            </Button>
                        </Col>
                        <Col className={styles.colButtonConfirm}>
                            <Button className={styles.buttonConfirm} onClick={handleFormSubmitSchedule}>
                                Confirmar
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </section>
    );
}
