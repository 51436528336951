import React from 'react';
import { Container, Carousel, Image } from 'react-bootstrap';
import { useBlogBannersData } from '../../api/blogAPI/blogClient';
import styles from "./style.module.scss"
import NoImage from "../../assets/no-image.jpg"

export default function SectionBannerBlog() {
    const { data, isLoading, isError } = useBlogBannersData();

    return (
        <section id={styles.SectionBannerBlog}>
            <Container>
                {isLoading ? (
                    <p>Carregando...</p>
                ) : isError ? (
                    <p>Erro ao carregar Imagens.</p>
                ) : ( 
                    data && data.length > 0 ? (
                        <Carousel className={styles.carousel}>
                            {data.map((post) => (
                                <Carousel.Item key={post.id}>
                                    <div className={styles.card}>
                                        <Image 
                                            src={post.image ? post.image : NoImage} 
                                            className={styles.image}
                                        />
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    ) : (
                        <p>Nenhuma imagem encontrado.</p>
                    )
                )}
            </Container>
        </section>
    );
}
