import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FloatingLabel, Button, Card } from 'react-bootstrap';
import styles from "./style.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../contexts/authContext';
import MaskedInput from 'react-text-mask';
import { isValidCPF } from '../../utils/utils';
import { UserAPI } from '../../api/userAPI/userClient';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function ProfilePage() {
  const { user, token } = useAuthContext();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    name: '',
    birth: '',
    email: '',
    about: '',
    cpf: '',
    contactLink: '',
    whatsapp: '',
  });
  const [errors, setErrors] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [validated, setValidated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showAllAddresses, setShowAllAddresses] = useState(false);

  const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  const whatsMask = ['+', '5', '5', ' ', '(', /[0-9]/, /\d/, ')', ' ', /[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  const addresses = [
    {
      cep: '12345-678',
      state: 'Estado 1',
      city: 'Cidade 1',
      neighborhood: 'Bairro 1',
      street: 'Rua 1',
      number: '123',
      complement: 'Apto 1',
      addressType: 'residential'
    },
    {
      cep: '87654-321',
      state: 'Estado 2',
      city: 'Cidade 2',
      neighborhood: 'Bairro 2',
      street: 'Avenida 2',
      number: '456',
      complement: 'Sala 2',
      addressType: 'work'
    }
  ];

  useEffect(() => {
    if (user) {
      const initialData = {
        name: user.name || '',
        birth: user.birth || '',
        email: user.email || '',
        cpf: user.cpf || '',
        about: user.about || '',
        contactLink: user.contactLink || '',
        whatsapp: user.whatsapp || ''
      };

      setFormValues(initialData);
      setInitialValues(initialData);
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.name) newErrors.name = "Nome completo é obrigatório.";
    if (!formValues.email) newErrors.email = "E-mail é obrigatório.";
    if (!formValues.cpf) {
      newErrors.cpf = "CPF é obrigatório.";
    } else if (!isValidCPF(formValues.cpf.replace(/\D/g, ''))) {
      newErrors.cpf = "CPF inválido.";
    }
    if (!formValues.whatsapp) {
        newErrors.whatsapp = "WhatsApp é obrigatório.";
    } else if (formValues.whatsapp.replace(/\D/g, '').length < 13) {
        newErrors.whatsapp = "WhatsApp deve ter pelo menos 13 dígitos.";
    }
    if (!formValues.birth) newErrors.birth = "Data de nascimento é obrigatória.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleEditPassword = () => {
    navigate('/changePassword');
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (!validateForm()) {
        setValidated(true);
        return;
    }

    const changedValues = {};
    Object.keys(formValues).forEach(key => {
        if (JSON.stringify(formValues[key]) !== JSON.stringify(initialValues[key])) {
            changedValues[key] = formValues[key];
        }
    });
    
    const formData = new FormData();
    
    if (changedValues.name) formData.append('name', formValues.name);
    if (changedValues.email) formData.append('email', formValues.email);
    if (changedValues.cpf) formData.append('cpf', formValues.cpf);
    if (changedValues.birth) formData.append('birth', formValues.birth);
    if (changedValues.about) formData.append('about', formValues.about);
    if (changedValues.contactLink) formData.append('contactLink', formValues.contactLink);
    if (changedValues.whatsapp) formData.append('whatsapp', formValues.whatsapp);

    try {
      const response = await UserAPI.patchUser(user.id, formData, token);

      if (response.status === 200) {
        toast.success('Dados alterados com sucesso!');
        setInitialValues(formValues);
        setIsEditing(false);
      } else if (response.status === 400) {
        toast.error('Erro ao alterar dados, email já utilizado!');
      } else if (response.status === 404) {
        toast.error('Erro ao fazer cadastro, verifique o email e senha!');
      }
    } catch (error) {
      console.error('Erro ao fazer cadastro:', error.message);
      toast.error('Erro ao fazer cadastro, verifique os dados e tente novamente!');
    }
  };

  const toggleShowAddresses = () => {
    setShowAllAddresses(!showAllAddresses);
  };

  return (
    <HelmetProvider>
      <section id={styles.ProfilePage}>
        <Helmet>
          <title>Meu Perfil - GoinGuru</title>
          <meta name="description" content="GoinGuru" />
        </Helmet>
        <Container>
          <Row className={styles.rowTitle}>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <p>Meu Perfil</p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6} className={styles.colButton}>
              <Button className={styles.buttonPassword} onClick={handleEditPassword}>Alterar Senha</Button>
              {!isEditing && <Button className={styles.button} onClick={handleEdit}>Editar</Button>}
            </Col>
          </Row>
          <Row className={styles.rowForm}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <FloatingLabel controlId="name" label="Nome Completo">
                    <Form.Control
                      type="text"
                      placeholder="Digite seu nome"
                      className={styles.formLabel}
                      name="name"
                      value={formValues.name}
                      onChange={handleInputChange}
                      maxLength="120"
                      required
                      disabled={!isEditing}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <FloatingLabel controlId="email" label="Email">
                    <Form.Control
                      type="email"
                      placeholder="Digite seu email"
                      className={styles.formLabel}
                      name="email"
                      value={formValues.email}
                      onChange={handleInputChange}
                      required
                      disabled={!isEditing}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <FloatingLabel controlId="birth" label="Data de Nascimento">
                    <Form.Control
                      type="date"
                      placeholder="Digite sua data de nascimento"
                      className={styles.formLabel}
                      name="birth"
                      value={formValues.birth}
                      onChange={handleInputChange}
                      required
                      disabled={!isEditing}
                    />
                    <Form.Control.Feedback type="invalid">{errors.birth}</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <FloatingLabel controlId="cpf" label="CPF">
                    <MaskedInput
                      mask={cpfMask}
                      className={styles.formLabel + " form-control"}
                      name="cpf"
                      value={formValues.cpf}
                      onChange={handleInputChange}
                      render={(ref, props) => (
                        <Form.Control
                          ref={ref}
                          {...props}
                          isInvalid={!!errors.cpf}
                          disabled={true}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.cpf}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel controlId="whatsapp" label="WhatsApp">
                    <MaskedInput
                      mask={whatsMask}
                      className={styles.formLabel + " form-control"}
                      value={formValues.whatsapp}
                      onChange={(e) => setFormValues({ ...formValues, whatsapp: e.target.value })}
                      render={(ref, props) => (
                          <Form.Control
                              ref={ref}
                              {...props}
                              isInvalid={!!errors.whatsapp}
                              disabled={!isEditing}
                          />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.whatsapp}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="contactLink" label="Link do Instagram">
                    <Form.Control
                      type="url"
                      placeholder="Digite seu link de contato"
                      className={styles.formLabel}
                      name="contactLink"
                      value={formValues.contactLink}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                    <Form.Control.Feedback type="invalid">Por favor, insira um link de contato válido.</Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <FloatingLabel controlId="about" label="Sobre" className="mt-3">
                <Form.Control
                  as="textarea"
                  placeholder="Digite algo sobre você"
                  className={styles.formLabel}
                  name="about"
                  value={formValues.about}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                />
              </FloatingLabel>
              {isEditing && (
                <Row className={styles.rowButton}>
                  <Col className={styles.colCancel}>
                    <Button className={styles.buttonCancel} onClick={handleCancel}>Cancelar</Button>
                  </Col>
                  <Col>
                    <Button className={styles.button} type="submit">Salvar</Button>
                  </Col>
                </Row>
              )}
            </Form>

            <Form>
              <Row className={styles.rowTitleAddress}>
                <Col>
                  <p>Meus Endereços</p>
                </Col>
                <Col className={styles.colButtonAdress}>
                  <Button className={styles.button}>Novo</Button>
                </Col>
              </Row>
              {addresses.slice(0, showAllAddresses ? addresses.length : 1).map((address, index) => (
                <Card key={index} className={styles.cardAdress}>
                  <Card.Body>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <FloatingLabel controlId={`nome-${index}`} label="Nome">
                          <Form.Control
                            type="text"
                            placeholder="Digite seu nome"
                            className={styles.formLabel}
                            value={address.nome}
                            readOnly
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <FloatingLabel controlId={`cep-${index}`} label="CEP">
                          <Form.Control
                            type="text"
                            placeholder="Digite seu CEP"
                            className={styles.formLabel}
                            value={address.cep}
                            readOnly
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <FloatingLabel controlId={`street-${index}`} label="Rua/Avenida">
                          <Form.Control
                            type="text"
                            placeholder="Digite sua rua ou avenida"
                            className={styles.formLabel}
                            value={address.street}
                            readOnly
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <FloatingLabel controlId={`number-${index}`} label="Número">
                          <Form.Control
                            type="text"
                            placeholder="Digite o número"
                            className={styles.formLabel}
                            value={address.number}
                            readOnly
                          />
                        </FloatingLabel>                        
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <FloatingLabel controlId={`neighborhood-${index}`} label="Bairro">
                          <Form.Control
                            type="text"
                            placeholder="Digite seu bairro"
                            className={styles.formLabel}
                            value={address.neighborhood}
                            readOnly
                          />
                        </FloatingLabel>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <FloatingLabel controlId={`state-${index}`} label="Estado">
                          <Form.Control
                            type="text"
                            placeholder="Digite seu estado"
                            className={styles.formLabel}
                            value={address.state}
                            readOnly
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <FloatingLabel controlId={`city-${index}`} label="Cidade">
                          <Form.Control
                            type="text"
                            placeholder="Digite sua cidade"
                            className={styles.formLabel}
                            value={address.city}
                            readOnly
                          />
                        </FloatingLabel>
                      </Col>
                      <Col>
                        <FloatingLabel controlId={`complement-${index}`} label="Complemento">
                          <Form.Control
                            type="text"
                            placeholder="Digite o complemento"
                            className={styles.formLabel}
                            value={address.complement}
                            readOnly
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                        <Form.Check
                          type="radio"
                          label="Trabalho"
                          name={`addressType-${index}`}
                          id={`work-${index}`}
                          value="work"
                          checked={address.addressType === 'work'}
                          readOnly
                        />
                      </Col>
                      <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                        <Form.Check
                          type="radio"
                          label="Residencial"
                          name={`addressType-${index}`}
                          id={`residential-${index}`}
                          value="residential"
                          checked={address.addressType === 'residential'}
                          readOnly
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))}
              <Row className={styles.rowVerMais}>
                <Button className={styles.buttonVerMais} onClick={toggleShowAddresses}>
                  {showAllAddresses ? 'Ver menos' : 'Ver mais'}
                </Button>
              </Row>
            </Form>
          </Row>
        </Container>
      </section>
    </HelmetProvider>
  );
}
