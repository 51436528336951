import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup, Spinner } from 'react-bootstrap';
import styles from "./style.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuthContext } from '../../contexts/authContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { PasswordAPI } from '../../api/recoverPassword';

export default function ChangePasswordPage() {
    const { token } = useAuthContext();
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const toggleShowPassword = (field) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formValues.oldPassword) newErrors.oldPassword = "Senha antiga é obrigatória.";
        if (!formValues.newPassword) newErrors.newPassword = "Nova senha é obrigatória.";
        if (!formValues.confirmPassword) {
            newErrors.confirmPassword = "Confirmação de senha é obrigatória.";
        } else if (formValues.newPassword !== formValues.confirmPassword) {
            newErrors.confirmPassword = "As senhas não coincidem.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleCancel = () => {
        navigate('/meuPerfil');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);

        try {
            const response = await PasswordAPI.postChangePassword(formValues.oldPassword, formValues.newPassword, token);

            if (response.status === 200) {
                toast.success('Senha alterada com sucesso!');
                setIsLoading(false);
                navigate('/meuPerfil');
            } else if (response.status === 400) {
                toast.error('Erro ao alterar senha, tente novamente!');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Erro ao alterar senha:', error.message);
            toast.error('Erro ao alterar senha, verifique os dados e tente novamente!');
        }

        setIsLoading(false);
    };

    return (
        <HelmetProvider>
            <section id={styles.ChangePasswordPage}>
                <Helmet>
                    <title>Alterar Senha - GoinGuru</title>
                    <meta name="description" content="GoinGuru" />
                </Helmet>
                <Container>
                    <Row className={styles.rowTitle}>
                        <p>Alterar Senha</p>
                    </Row>
                    <Row className={styles.rowForm}>
                        <Form noValidate onSubmit={handleSubmit} className={styles.form}>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group controlId="oldPassword">
                                        <Form.Label className={styles.formLabel}>Senha Antiga</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type={showPassword.oldPassword ? "text" : "password"}
                                                className={styles.formControl}
                                                name="oldPassword"
                                                value={formValues.oldPassword}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.oldPassword}
                                            />
                                            <Button
                                                onClick={() => toggleShowPassword('oldPassword')}
                                                className={styles.showPasswordButton}
                                            >
                                                {showPassword.oldPassword ? <FaEyeSlash /> : <FaEye />}
                                            </Button>
                                            <Form.Control.Feedback type="invalid">{errors.oldPassword}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group controlId="newPassword">
                                        <Form.Label className={styles.formLabel}>Nova Senha</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type={showPassword.newPassword ? "text" : "password"}
                                                className={styles.formControl}
                                                name="newPassword"
                                                value={formValues.newPassword}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.newPassword}
                                            />
                                            <Button
                                                onClick={() => toggleShowPassword('newPassword')}
                                                className={styles.showPasswordButton}
                                            >
                                                {showPassword.newPassword ? <FaEyeSlash /> : <FaEye />}
                                            </Button>
                                            <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group controlId="confirmPassword">
                                        <Form.Label className={styles.formLabel}>Confirme a senha</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type={showPassword.confirmPassword ? "text" : "password"}
                                                className={styles.formControl}
                                                name="confirmPassword"
                                                value={formValues.confirmPassword}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.confirmPassword}

                                            />
                                            <Button
                                                onClick={() => toggleShowPassword('confirmPassword')}
                                                className={styles.showPasswordButton}
                                            >
                                                {showPassword.confirmPassword ? <FaEyeSlash /> : <FaEye />}
                                            </Button>
                                            <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={styles.rowButton}>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6} className={styles.colCancel}>
                                    <Button className={styles.buttonCancel} onClick={handleCancel}>Cancelar</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6} className={styles.colButton}>
                                    <Button className={styles.button} type="submit" disabled={isLoading}>
                                        {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Row>
                </Container>
            </section>
        </HelmetProvider>
    );
}
