import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Image, Card, Form, Alert } from 'react-bootstrap';
import styles from "./style.module.scss";
import { useParams, useNavigate } from 'react-router-dom';
import { useOnePostData, usePostNewsletter } from '../../../api/blogAPI/blogClient';
import { IoChevronBack } from 'react-icons/io5';
import ReactQuill from 'react-quill';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { formatDate } from '../../../utils/utils';
import Noimage from "../../../assets/no-image.jpg"

export default function PostBlogPage() {
    const { id } = useParams();
    const { data, isLoading, isError } = useOnePostData(id);

    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/blog');
    };

    const [email, setEmail] = useState('');
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const { mutate: postNewsletter, isSuccess: isSuccessPost, isError: isErrorPost, isLoading: isPosting } = usePostNewsletter();
    
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('success');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleTermsChange = () => {
        setAcceptedTerms(!acceptedTerms);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postNewsletter({ email });
    };

    useEffect(() => {
        if (isSuccessPost) {
            setEmail('');
            setAcceptedTerms(false);
            setAlertMessage('Inscrição realizada com sucesso!');
            setAlertVariant('success');
            setShowAlert(true);
        } else if (isErrorPost) {
            setAlertMessage('Erro ao realizar inscrição. Por favor, tente novamente.');
            setAlertVariant('danger');
            setShowAlert(true);
        }
    }, [isSuccessPost, isErrorPost]);

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => setShowAlert(false), 5000);
            return () => clearTimeout(timer);
        }
    }, [showAlert]);

    return (
        <HelmetProvider>
            <section id={styles.PostBlogPage}>
                <div className={styles.divContainer}>
                    {isLoading ? (
                        <p>Carregando...</p>
                    ) : isError ? (
                        <p>Erro ao carregar Imagens.</p>
                    ) : (
                        data ? (
                            <Container className={styles.container}>
                                <Helmet>
                                    <title>{data.title}</title>
                                    <meta name="description" content={data.resume} />
                                </Helmet>
                                <Row>
                                    <Col xs={12} md={1} className={styles.colButton}>
                                        <Button onClick={handleHome} className={styles.button}>
                                            <IoChevronBack className={styles.icon} />
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={11}>
                                        <p className={styles.title}>{data.title}</p>
                                    </Col>
                                </Row>
                            </Container>
                        ) : (
                            <p>Nenhuma imagem encontrada.</p>
                        )
                    )}
                </div>
                <div className={styles.divBanner}>
                    {isLoading ? (
                        <p>Carregando...</p>
                    ) : isError ? (
                        <p>Erro ao carregar Imagens.</p>
                    ) : (
                        data ? (
                            <Image 
                                src={data.banner ? data.banner : Noimage} 
                                className={styles.banner}
                            />
                        ) : (
                            <p>Nenhuma imagem encontrada.</p>
                        )
                    )}
                </div>
                <div className={styles.divCategories}>
                    {isLoading ? (
                        <p>Carregando...</p>
                    ) : isError ? (
                        <p>Erro ao carregar categorias.</p>
                    ) : (
                        data && data.bodyPost ? (
                            <Container>
                                <Row className={styles.rowDate}>
                                    <p className={styles.date}>{formatDate(data.created)}</p>
                                </Row>
                                <Row className={styles.rowCategory}>
                                    {data.categories.map((category) => (
                                        <div key={category.id} className={styles.divCategory}>
                                            <p className={styles.category}>{category.name}</p>
                                        </div>
                                    ))}
                                </Row>
                            </Container>
                        ) : (
                            <p>Nenhuma categorias encontrado.</p>
                        )
                    )}
                </div>
                <div className={styles.divBodyPost}>
                    {isLoading ? (
                        <p>Carregando...</p>
                    ) : isError ? (
                        <p>Erro ao carregar post.</p>
                    ) : (
                        data && data.bodyPost ? (
                            <Container>
                                <ReactQuill
                                    value={data.bodyPost}
                                    readOnly={true}
                                    modules={{
                                        toolbar: false
                                    }}
                                    theme="bubble"
                                />
                            </Container>
                        ) : (
                            <p>Nenhuma post encontrado.</p>
                        )
                    )}
                </div>
                <div className={styles.divNewsletter}>
                    <Container className={styles.container}>
                        <Card className={styles.card}>
                            <Form onSubmit={handleSubmit}>
                                <p className={styles.title}>Gostou do conteúdo?</p>
                                <p className={styles.subTitle}>Cadastre-se no NewsLetter</p>
                                <p className={styles.subTitle}>E aproveite para receber as novos informações e conteúdos!</p>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        checked={acceptedTerms}
                                        onChange={handleTermsChange}
                                        className={styles.checkbox}
                                        label="Aceito receber conteúdos da GoinGuru"
                                        required
                                    />
                                </Form.Group>
                                {showAlert && (
                                    <Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>
                                        {alertMessage}
                                    </Alert>
                                )}
                                <Row>
                                    <Col xs={12} md={10}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control
                                                type="email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                className={styles.formControl}
                                                placeholder="Seu Email"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={2}>
                                        <Button type="submit" className={styles.button} disabled={isPosting}>
                                            {isPosting ? "Enviando..." : "Enviar"}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Container>
                </div>
            </section>
        </HelmetProvider>
    );
}
