import { HttpResponse, HttpStatus, BASE_URL, AUTH_DEBUG } from "./default";

export const getUserInfo = async (jwt) => {
    const url = `${BASE_URL}/user-info/`;
    try {
        const options = {
            method: 'GET',
            credentials: 'include',
            headers: {
                jwt: jwt,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
                
            AUTH_DEBUG && console.log("AuthAPI::getUserInfo(): ", data);
            return new HttpResponse(HttpStatus.OK, data);
        } else throw new Error("Error on getUserInfo()");
    } catch (error) {
        console.warn(error);
        return new HttpResponse(HttpStatus.ERROR, null);
    }
}


