import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image, Button, Dropdown, Modal, Alert, Spinner } from 'react-bootstrap';
import styles from "./style.module.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BiClipboard, BiDownArrow, BiGroup, BiListCheck, BiLogoWhatsapp, BiMailSend, BiMapPin, BiSolidStar, BiUpArrow } from 'react-icons/bi';
import NoImage from "../../assets/no-image.jpg";
import { format, setDefaultOptions, addMinutes } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useClientPartnerPerfilData } from '../../api/userAPI/userClient';
import { useNavigate, useParams } from 'react-router-dom';
import { CiMail } from "react-icons/ci";
import { FaClipboardList, FaRegClock } from "react-icons/fa";
import DatePaginationPartner from '../../components/datePaginationPartner';
import { SearchAPI } from '../../api/homeAPI/homeClient';
import { useAuthContext } from '../../contexts/authContext';
import { toast } from 'react-toastify';

setDefaultOptions({ locale: ptBR });

const formatDuration = (duration) => {
    if (duration) {
        return duration.substring(0, 5);
    }
    return null;
};

export default function PartnerPage() {
    const { partner_id } = useParams();
    const { token, user } = useAuthContext();
    const navigate = useNavigate();
    const { PerfilData, isError, isLoading } = useClientPartnerPerfilData(partner_id);
    const [serviceData, setServiceData] = useState(null);
    const [showServiceDetails, setShowServiceDetails] = useState(null);
    const [showWorkingHours, setShowWorkingHours] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState({
        service: null,
        partnerId: null,
        time: null,
        employee: null,
        date: null
    });
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const googleMapsLink = PerfilData ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        `${PerfilData.a_street_name}, Nº ${PerfilData.a_number} - ${PerfilData.a_neighborhood}, ${PerfilData.a_city}/${PerfilData.a_state} - ${PerfilData.a_postal_code} ${PerfilData.a_complement ? ', ' + PerfilData.a_complement : ''}`
    )}` : '';

    const formatWorkingHours = (workingHours) => {
        const daysOfWeek = [
            { key: 'monday', label: 'Segunda-feira' },
            { key: 'tuesday', label: 'Terça-feira' },
            { key: 'wednesday', label: 'Quarta-feira' },
            { key: 'thursday', label: 'Quinta-feira' },
            { key: 'friday', label: 'Sexta-feira' },
            { key: 'saturday', label: 'Sábado' },
            { key: 'sunday', label: 'Domingo' }
        ];

        return daysOfWeek.map(day => {
            const { key, label } = day;
            const hours = workingHours[key];
            if (!hours || !hours.is_active) return null;

            const { has_break, open_time, close_time, break_start_time, break_end_time } = hours;
            let breakTime = '';
            if (has_break) {
                breakTime = ` (Intervalo: ${break_start_time} - ${break_end_time})`;
            }

            return (
                <Col key={key} xs={12} sm={12} md={12} className={styles.colWorkingHours}>
                    <p><strong>{label}</strong>: {open_time} às {close_time}{breakTime}</p>
                </Col>
            );
        });
    };

    const handleServiceSelect = (service) => {
        setServiceData(prevData => ({
            ...prevData,
            selectedService: service,
        }));
    };

    const handleTimeSelect = (selection, partnerId) => {
        setServiceData(prevData => ({
            ...prevData,
            selectedService: prevData?.selectedService || null,
            availableEmployees: selection.availableEmployees,
            date: selection.date,
            time: selection.time,
        }));
    };

    const handleEmployeeSelect = (employee, partnerId) => {
        setSelectedSchedule(prevSchedule => ({
            ...prevSchedule,
            employee: employee,
            service: serviceData?.selectedService || prevSchedule.service,
            date: serviceData?.date || prevSchedule.date,
            time: serviceData?.time || prevSchedule.time,
        }));
        console.log('teste', serviceData);
    };

    useEffect(() => {
        const { service, date, time, employee } = selectedSchedule;

        if (service && date && time && employee) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [selectedSchedule]);

    const handleFormSubmitSchedule = async () => {
        if (!user || !user.id) {
            toast.error('Usuário não está autenticado. Por favor, faça o login e tente novamente!');
            return;
        }
    
        const { service, date, time, employee } = selectedSchedule;
        console.log(service, date, time, employee, partner_id)
    
        if (!service || !date || !time || !employee || !partner_id) {
            toast.error('Dados incompletos para o agendamento. Por favor, preencha todos os campos!');
            return;
        }

        const formattedDate = format(new Date(date), 'yyyy-MM-dd');
        const startDateTime = `${formattedDate}T${time}:00`;

        const [durationHours, durationMinutes] = formatDuration(service?.duration)?.split(':').map(Number) || [0, 0];
        const endDateTime = addMinutes(new Date(startDateTime), durationHours * 60 + durationMinutes);
        const formattedEndDateTime = format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss");

        const formData = new FormData();
        formData.append('partner', partner_id);
        formData.append('client', user.id);
        formData.append('employee', employee?.id);
        formData.append('service', service?.id);
        formData.append('start_time', startDateTime);
        formData.append('end_time', formattedEndDateTime);
        formData.append('status', 'Agendado');

        try {
            const response = await SearchAPI.postSchedule(formData, token);
    
            if (response.status === 200) {
                toast.success('Agendamento realizado com sucesso!');
                navigate('/meusAgendamentos');
            } else if (response.status === 400 && token) {
                toast.error('Erro ao fazer agendamento, horário indesponível, por favor atulizar a página!');
            } else if (response.status === 404) {
                toast.error('Erro ao fazer agendamento, verifique os dados novamente!');
            } else if (response.status === 400 && !token) {
                toast.error('Erro ao fazer agendamento, realize o login e tente novamente!');
            }
        } catch (error) {
            console.error('Erro ao fazer agendamento:', error.message);
            toast.error('Erro ao fazer agendamento, verifique os dados e tente novamente!');
        }

        setSelectedSchedule({
            service: null,
            partnerId: null,
            time: null,
            employee: null,
            date: null
        });
    };

    if (isLoading) {
        return (
            <section id={styles.PartnerPage}>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" role="status">
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    if (isError) {
        return (
            <section id={styles.PartnerPage}>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="danger">
                                Erro ao carregar os dados. Por favor, tente novamente mais tarde.
                            </Alert>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    return (
        <HelmetProvider>
            <section id={styles.PartnerPage}>
                <Helmet>
                    <title>Parceiro Guru - GoinGuru</title>
                    <meta name="description" content="GoinGuru" />
                </Helmet>
                <Container className={styles.container}>
                    {PerfilData &&
                        <Row className={styles.rowPartnerData}>
                            <Row className={styles.rowPartner}>
                                <Col xs={12} sm={12} md={4} lg={3} xl={3} className={styles.colLogoPartner}>
                                    <Image className={styles.logoPartner} src={PerfilData.photo || NoImage} />
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={9} xl={9} className={styles.colInfoPartner}>
                                    <p className={styles.titlePartner}>{PerfilData.name}</p>
                                    <p className={styles.titleMapPartner}><BiMapPin className={styles.icon}/> Endereço</p>
                                    <a href={googleMapsLink} target="_blank" rel="noopener noreferrer" className={styles.mapsLink}>
                                        <p className={styles.textPartner}>{PerfilData.a_street_name}, Nº {PerfilData.a_number} - {PerfilData.a_neighborhood}, {PerfilData.a_city}/{PerfilData.a_state} - {PerfilData.a_postal_code}</p>
                                        {PerfilData.a_complement && (
                                            <p className={styles.textPartner}>{PerfilData.a_complement}</p>
                                        )}
                                    </a>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <p className={styles.titleMapPartner}><CiMail className={styles.icon}/> Email</p>
                                            <p className={styles.textPartner}>{PerfilData.email}</p>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <p className={styles.titleMapPartner}><BiLogoWhatsapp className={styles.icon}/> WhatsApp</p>
                                            <p className={styles.textPartner}>{PerfilData.whatsapp}</p>
                                        </Col>
                                    </Row>
                                    <p className={styles.textPartner}>
                                        {PerfilData.about}
                                    </p>
                                </Col>
                            </Row>
                            <Row className={styles.rowWorkingPartner}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                    <Row className={styles.rowWorkingHours} onClick={() => setShowWorkingHours(!showWorkingHours)}>
                                        <Col xs={6}>
                                            <p className={styles.titleIconPartner}>
                                                <FaRegClock className={styles.icon}/> Horário de Funcionamento 
                                            </p>
                                        </Col>
                                        <Col xs={6} className={styles.colWorkingHoursHeader}>
                                            <Button
                                                className={styles.workingButton}
                                            >
                                                {showWorkingHours ? <BiUpArrow /> : <BiDownArrow />}
                                            </Button>
                                        </Col>
                                        {showWorkingHours && (
                                            <Row className={styles.rowHours}>
                                                {formatWorkingHours(PerfilData.working_hours)}
                                            </Row>
                                        )}
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                                    <Row className={styles.rowTextsPartner}>
                                        <div className={styles.servicesList}>
                                            <p className={styles.titleIconPartner}><BiListCheck className={styles.icon}/> Categorias</p>
                                            <p className={styles.textPartner}>
                                                {PerfilData.services_partners.map((service, index) => (
                                                    <span key={index}>
                                                        {service.name}{index < PerfilData.services_partners.length - 1 ? ', ' : ''}
                                                    </span>
                                                ))}
                                            </p>
                                        </div>
                                        {/* <p className={styles.textPartner}><BiSolidStar className={styles.iconStar} />45 Avaliações</p> */}
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={styles.rowServicesPartner}>
                                <p className={styles.titleIconService}><FaClipboardList className={styles.icon}/> Serviços</p>
                                {PerfilData.services_provided.map(service => (
                                    <div key={service.id} className={styles.serviceContainer}>
                                        <div 
                                            className={styles.serviceHeader} 
                                            onClick={() => setShowServiceDetails(service.id === showServiceDetails ? null : service.id)}
                                        >
                                            <Row className={styles.rowInfo}>
                                                <Col xs={3} sm={2} md={2} lg={1} xl={1} className={styles.colService}>
                                                    <Image src={service.photo} alt={service.name} className={styles.serviceImage} />
                                                </Col>
                                                <Col xs={9} sm={10} md={4} lg={5} xl={5} className={styles.serviceDetails}>
                                                    <p><strong>{service.name}</strong></p>
                                                </Col>
                                                <Col xs={4} sm={4} md={2} lg={2} xl={2} className={styles.serviceDetails}>
                                                    <p>Preço: {service.price ? `R$ ${service.price}` : 'Sob consulta'}</p>
                                                </Col>
                                                <Col xs={4} sm={4} md={2} lg={2} xl={2} className={styles.serviceDetails}>
                                                    <p>Duração: {service.duration}</p>
                                                </Col>
                                                <Col xs={4} sm={4} md={2} lg={2} xl={2} className={styles.serviceDetails}>
                                                    <p>
                                                        {service.is_online_service ? 'Serviço Online' : 'Serviço Presencial'} {service.is_home_service ? '(Home Service)' : ''}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                        {service.id === showServiceDetails && (
                                            <div className={styles.serviceBody}>
                                                <p className={styles.serviceDescription}>{service.description}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </Row>
                            <Row className={styles.rowContainerSchedules}>
                                <Row className={styles.rowSchedules}>
                                    <p className={styles.titleSchedules}><BiClipboard className={styles.icon}/> Serviços Disponíveis</p>
                                    <Dropdown>
                                        <Dropdown.Toggle id={`dropdown-services-${PerfilData}`} className={styles.dropdownSchedules}>
                                            {serviceData ? serviceData.selectedService.name : 'Selecionar Serviço'}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className={styles.dropdownSchedulesMenu}>
                                            {PerfilData.services_provided.map(service => (
                                                <Dropdown.Item
                                                    key={service.id}
                                                    onClick={() => handleServiceSelect(service)}
                                                >
                                                    {service.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Row>
                                {serviceData && (
                                    <>
                                        <DatePaginationPartner
                                            partner_id={partner_id}
                                            selectedService={serviceData?.selectedService}
                                            partnerData={serviceData.working_hours}
                                            duration={serviceData?.selectedService ? formatDuration(serviceData.selectedService.duration) : null}
                                            onTimeSelect={(selection) => handleTimeSelect(selection, partner_id)}
                                        />
                                        {serviceData?.availableEmployees && serviceData.availableEmployees.length > 0 && (
                                            <Row className={styles.rowProfPartner}>
                                                <p className={styles.titleProfPartner}><BiGroup className={styles.icon}/> Profissionais</p>
                                                {serviceData.availableEmployees.map((employee) => (
                                                    <Col 
                                                        key={employee.id} 
                                                        xs={4} sm={3} md={3} lg={2} xl={2} 
                                                        className={styles.colProfPartner}
                                                        onClick={() => handleEmployeeSelect(employee, partner_id)}
                                                    >
                                                        <Image className={styles.photoProfPartner} src={employee.photo} />
                                                        <p className={styles.nameProfPartner}>{employee.name}</p>
                                                    </Col>
                                                ))}
                                            </Row>
                                        )}
                                    </>
                                )}
                            </Row>
                        </Row>
                    }
                </Container>
                <Modal show={showModal} onHide={handleCloseModal} size="lg" centered className={styles.modal}>
                    <Modal.Header closeButton>
                        <Modal.Title className={styles.title}>Agendamento do serviço</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4} className={styles.colLogoService}>
                                <Image className={styles.logoService} src={selectedSchedule.service?.photo || NoImage} />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <p className={styles.text}><strong>Serviço:</strong> {selectedSchedule.service?.name}</p>
                                <p className={styles.text}><strong>Descrição:</strong> {selectedSchedule.service?.description}</p>
                                {!selectedSchedule.service?.is_custom_quote ? (
                                    <p className={styles.text}><strong>Preço:</strong> R$ {selectedSchedule.service?.price}</p>
                                ) : (
                                    <p className={styles.text}><strong>Preço:</strong> A combinar</p>
                                )}
                                {!selectedSchedule.service?.is_online_service ? (
                                    <p className={styles.text}><strong>Atendimento:</strong> No local</p>
                                ) : (
                                    <p className={styles.text}><strong>Atendimento:</strong> Online</p>
                                )}
                                <p className={styles.text}>
                                    <strong>Data:</strong> {selectedSchedule.date ? format(new Date(selectedSchedule.date), 'dd/MM/yyyy') : ''}
                                </p>
                                <p className={styles.text}><strong>Horário:</strong> {selectedSchedule.time}</p>
                                <p className={styles.text}><strong>Duração:</strong> {formatDuration(selectedSchedule.service?.duration)}</p>
                                <p className={styles.text}><strong>Profissional:</strong> {selectedSchedule.employee ? selectedSchedule.employee.name : 'Nenhum profissional disponível'}</p>
                            </Col>
                        </Row>
                        <Row className={styles.rowButton}>
                            <Col className={styles.colButtonCancel}>
                                <Button className={styles.buttonCancel} onClick={handleCloseModal}>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col className={styles.colButtonConfirm}>
                                <Button className={styles.buttonConfirm} onClick={handleFormSubmitSchedule}>
                                    Confirmar
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </section>
        </HelmetProvider>
    );
}
