import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import styles from "./style.module.scss"
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import logoGuru from "../../assets/logo.png"

export default function Footer() {
    const openUrlInNewTab = (url) => {
        window.open(url, '_blank');
    };

    return (
        <section id={styles.Footer}>
            <Container>
                <Row>
                    <Col xs={12} md={9}>
                        <Row>
                            <Col xs={6} md={3}>
                                <Row className={styles.rowContato}>
                                    <p className={styles.contato}>Serviços</p>
                                </Row>
                            </Col>
                            <Col xs={6} md={4}>
                                <Row className={styles.rowContato}>
                                    <p className={styles.contato}>Contato</p>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={3}>
                        <Row className={styles.rowIcon}>
                            <AiFillInstagram
                                className={styles.icon}
                                onClick={() => openUrlInNewTab("https://www.instagram.com/goinguru")}
                            />
                            <AiFillFacebook
                                className={styles.icon}
                                onClick={() => openUrlInNewTab("https://www.facebook.com/goinguru.of")}
                            />
                            <AiFillLinkedin
                                className={styles.icon}
                                onClick={() => openUrlInNewTab("https://www.linkedin.com")}
                            />
                        </Row>
                        <Row className={styles.rowLogo}>
                            <Image src={logoGuru} className={styles.logo}/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
