import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styles from "./style.module.scss"
import { useNavigate } from 'react-router-dom';
import SectionBlog from '../../sections/sectionBlog';
import { IoChevronBack } from 'react-icons/io5';
import SectionBannerBlog from '../../sections/sectionBannerBlog';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function BlogPage() {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/');
  };

  return (
    <HelmetProvider>
      <section id={styles.BlogPage}>
        <Helmet>
          <title>Blog - GoinGuru</title>
          <meta name="description" content="Blog Ofical da GoinGuru" />
        </Helmet>
        <div className={styles.divContainer}>
          <Container className={styles.container}>
            <Row>
              <Col xs={12} md={1} className={styles.colButton}>
                <Button onClick={handleHome} className={styles.button}>
                  <IoChevronBack className={styles.icon}/>
                </Button>
              </Col>
              <Col xs={12} md={11}>
                <p className={styles.title}>CAMINHOS PARA O SUCESSO: Dicas, Insights e Inspiração</p>
              </Col>
            </Row>
          </Container>
        </div>
        <SectionBannerBlog />
        <SectionBlog />
      </section>
    </HelmetProvider>
  );
}
