import React, { useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import styles from "./style.module.scss";
import { useBlogResumeData } from '../../api/blogAPI/blogClient';
import Noimage from "../../assets/no-image.jpg";
import { Link } from 'react-router-dom';
import Pagination from '../../components/pagination';
import { formatDate, truncateText } from '../../utils/utils';

export default function SectionBlog() {
    const [page, setPage] = useState(1);
    const { data, isLoading, isError } = useBlogResumeData(page);

    const itemsPerPage = 10;

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    return (
        <section id={styles.SectionBlog}>
            <Container>
                <Row>
                    {isLoading ? (
                        <p>Carregando...</p>
                    ) : isError ? (
                        <p>Erro ao carregar Posts.</p>
                    ) : (
                        data && data.results && data.results.length > 0 ? (
                            data.results.map((post) => (
                                <Col key={post.id} xs={12} sm={12} md={6} lg={6} xl={4} className={styles.colCard}>
                                    <div className={styles.card}>
                                        <Link to={`/blog/post/${post.id}`} className={styles.links}>
                                            <Image 
                                                src={post.banner ? post.banner : Noimage} 
                                                className={styles.image}
                                            />
                                            <p className={styles.date}>{formatDate(post.created)}</p>
                                            <Row className={styles.rowCategory}>
                                                {post.categories.slice(0, 2).map((category) => (
                                                    <div key={category.id} className={styles.divCategory}>
                                                        <p className={styles.category}>{category.name}</p>
                                                    </div>
                                                ))}
                                            </Row>
                                            <p className={styles.title}>{truncateText(post.title, 65)}</p>
                                            <p className={styles.resume}>{truncateText(post.resume, 170)}</p>
                                        </Link>
                                    </div>
                                </Col>
                            ))
                        ) : (
                            <p>Nenhum post encontrado.</p>
                        )
                    )}
                </Row>
                <Row>
                    {data && (
                        <Pagination
                            totalPages={Math.ceil(data.count / itemsPerPage)}
                            onPageChange={handlePageChange}
                            currentPage={page}
                        />
                    )}
                </Row>
            </Container>
        </section>
    );
}
