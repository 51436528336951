import React, { useState, useMemo } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import styles from "./style.module.scss";
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BiSearch } from 'react-icons/bi';
import { useCategoriesResumeHomeData } from '../../api/homeAPI/homeClient';

// Exemplos de opções para Cidade
const citiesOptions = [
    { value: 'são-paulo', label: 'São Paulo' },
    { value: 'rio-de-janeiro', label: 'Rio de Janeiro' },
    { value: 'belo-horizonte', label: 'Belo Horizonte' },
    { value: 'porto-alegre', label: 'Porto Alegre' },
    { value: 'curitiba', label: 'Curitiba' },
    { value: 'brasília', label: 'Brasília' },
    { value: 'salvador', label: 'Salvador' },
    { value: 'fortaleza', label: 'Fortaleza' },
    { value: 'manaus', label: 'Manaus' },
    { value: 'recife', label: 'Recife' }
];

// Função para customizar estilos do react-select
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        marginTop: '5px',
        borderColor: '#EE880C',
        boxShadow: state.isFocused ? '0 0 5px 2px #EE880C' : provided.boxShadow,
        '&:hover': {
            borderColor: '#EE880C'
        }
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        '&:hover': {
            color: '#EE880C'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#390040' : state.isFocused ? '#EE880C' : provided.backgroundColor,
        color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#EE880C',
            color: '#fff'
        }
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#390040',
    }),
};

export default function SectionSearchHome() {
    const [selectedTags, setSelectedTags] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [searchType, setSearchType] = useState("local"); 
    const [serviceName, setServiceName] = useState('');
    const navigate = useNavigate();

    const { data: servicesHomeData } = useCategoriesResumeHomeData();

    const TagsOptions = useMemo(() => 
        servicesHomeData?.services?.map(service => ({
            value: service.id, 
            label: service.name
        })) || [], 
        [servicesHomeData]
    );

    const handleSearch = () => {
        if ((selectedTags || serviceName) && selectedCity && searchType) {
            const tag = selectedTags ? selectedTags.label : serviceName;
            const city = selectedCity.label;
            navigate(`/pesquisa?serv=${serviceName}&tags=${tag}&loc=${city}&type=${searchType}`);
        } else {
            toast.error('Por favor, informe ou selecione um serviço e cidade!');
        }
    };

    return (
        <section id={styles.SectionSearchHome}>
            <Container>
                <Row className={styles.rowSearchText}>
                    <p className={styles.text}>Encontre o GuruParceiro mais próximo de você!</p>
                </Row>
                <Row className={styles.rowSearchType}>
                    <Col xs={6} sm={6} md={6} lg={2} xl={2}>
                        <Button
                            onClick={() => setSearchType('local')}
                            className={`${styles.buttonType} ${searchType === 'local' ? styles.buttonTypeSelected : ''}`}
                        >
                            No Local
                        </Button>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={2} xl={2}>
                        <Button
                            onClick={() => setSearchType('online')}
                            className={`${styles.buttonType} ${searchType === 'online' ? styles.buttonTypeSelected : ''}`}
                        >
                            Online
                        </Button>
                    </Col>
                </Row>
                <Row className={styles.rowSearch}>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Form.Control
                            type="text"
                            placeholder="Qual serviço você tá procurando?"
                            value={serviceName}
                            onChange={(e) => setServiceName(e.target.value)}
                            className={styles.formLabel}
                            maxLength={55}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                        <Select
                            options={TagsOptions}
                            value={selectedTags}
                            onChange={setSelectedTags}
                            placeholder="Categorias do serviço"
                            styles={customStyles}
                            isClearable
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                        <Select
                            options={citiesOptions}
                            value={selectedCity}
                            onChange={setSelectedCity}
                            placeholder="Cidade"
                            styles={customStyles}
                            isClearable
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Button onClick={handleSearch} className={styles.buttonSearch}><BiSearch className={styles.icon}/> Pesquisar</Button>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
