import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Image, FloatingLabel, Spinner } from 'react-bootstrap';
import styles from "./style.module.scss";
import logoGoinguru from "../../assets/logo.png";
import { PasswordAPI } from '../../api/recoverPassword';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from 'react-toastify';

export default function RecoverPasswordPage() {
    const [email, setEmail] = useState('');
    const [validated, setValidated] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [showRecaptcha, setShowRecaptcha] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
      setShowRecaptcha(email !== '');
    }, [email]);

    const handleHome = () => {
      navigate('/');
    };

    const handleSignUp = () => {
      navigate('/signup');
    };

    const handleSubmit = async (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();

      if (form.checkValidity() === false) {
        setValidated(true);
        return;
      }

      setIsLoading(true);

      if (!captchaValue) {
        console.error('Por favor, resolva o reCAPTCHA.');
        return;
      }

    try {
    const response = await PasswordAPI.postRecover(email);

    if (response.status === 200) {
      toast.success('Recuperação de senha realizado com sucesso! Verfique o código enviado no seu email!');
      setIsLoading(false);
      navigate('/');
    } else if (response.status === 400) {
      toast.error('Erro ao fazer Recuperação de senha, verifique o email!');
    } else if (response.status === 404) {
      toast.error('Erro ao fazer Recuperação de senha, verifique o email!');
      setIsLoading(false);
    }
    } catch (error) {
      console.error('Erro ao fazer Recuperação de senha:', error.message);
      toast.error('Erro ao fazer Recuperação de senha, verifique o email!');
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <section id={styles.SectionLogin}>
      <Container className={styles.containerLogin}>
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <Row className={styles.rowLogo}>
              <Image src={logoGoinguru} className={styles.logoLogin} onClick={handleHome} />
            </Row>
            <Row className={styles.rowTitle}>
              <p>Recuperar Senha</p>
            </Row>
            <Row className={styles.rowForm}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <FloatingLabel controlId="email" label="Email">
                  <Form.Control
                    type="email"
                    placeholder="Digite seu email"
                    className={styles.formLabel}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">Por favor, insira um email válido.</Form.Control.Feedback>
                </FloatingLabel>
                {showRecaptcha && (
                  <Row className={styles.rowRecaptcha}>
                    <ReCAPTCHA
                      sitekey="6LeaPe0pAAAAAG44B5AzphXhmL8IKsmJ9QUTjTsF"
                      onChange={handleCaptchaChange}
                      className={styles.recaptcha}
                    />
                  </Row>
                )}
                <Button className={styles.button} type="submit" disabled={isLoading}>
                    {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Salvar"}
                </Button>
              </Form>
            </Row>
            <Row className={styles.rowSignUp}>
              <p className={styles.signUpText}>Ainda não tem conta? <strong onClick={handleSignUp}>Faça seu cadastro</strong></p>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
