import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import styles from "./style.module.scss";

export default function Pagination({ totalPages, onPageChange }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [maxButtons, setMaxButtons] = useState(10);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setMaxButtons(3);
      } else {
        setMaxButtons(10); 
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const renderPageButtons = () => {
    const buttons = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > maxButtons) {
      if (currentPage <= Math.ceil(maxButtons / 2)) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + Math.floor(maxButtons / 2) >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxButtons / 2);
        endPage = currentPage + Math.floor(maxButtons / 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          className={i === currentPage ? styles.buttonPress : styles.button}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Button>
      );
    }
    return buttons;
  };

  return (
    <section id={styles.Pagination}>
      <Container className={styles.container}>
        <div className={styles.rowButton}>
          <Button onClick={handlePrevious} disabled={currentPage === 1} className={styles.button}>
            <BiChevronLeft />
          </Button>
          {renderPageButtons()}
          <Button onClick={handleNext} disabled={currentPage === totalPages} className={styles.button}>
            <BiChevronRight />
          </Button>
        </div>
      </Container>
    </section>
  );
}
