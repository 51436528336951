/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useCallback } from 'react';
import { HttpStatus, AUTH_DEBUG } from '../api/default';
import { getUserInfo } from '../api/auth';

const AuthContext = React.createContext({
    up: false,
    logged: false,
    user: null,
    token: null,
    setToken: async () => {},
    refreshUserOnContext: () => { console.log("Access refreshUserOnContext before initialization") }
});

export const AuthProvider = ({ children }) => {
    const [context, setContext] = useState({
        logged: false,
        user: null,
        token: null,
        setToken: async () => {},
        refreshUserOnContext: () => { console.log("Access refreshUserOnContext before initialization") }
    });

    const setToken = useCallback(async (token) => {
        AUTH_DEBUG && console.log("AuthProvider::setToken(): ", token);
        try {
            if (!!token) {
                const response = await getUserInfo(token);
                if (response.status === HttpStatus.OK) {
                    setContext(prevContext => ({
                        ...prevContext,
                        up: true,
                        user: response.data,
                        logged: true,
                        token: token,
                        refreshUserOnContext
                    }));
                    return true;
                } else {
                    throw new Error("token is not valid!");
                }
            } else {
                throw new Error("token is not valid!");
            }
        } catch (error) {
            setContext(prevContext => ({
                ...prevContext,
                up: true,
                user: null,
                logged: false,
                token: null,
                refreshUserOnContext
            }));
            return false;
        }
    }, []);

    const refreshUserOnContext = useCallback(() => {
        const token = localStorage.getItem("token");
        if (token) setToken(token);
    }, [setToken]);

    useEffect(() => {
        refreshUserOnContext();
    }, [refreshUserOnContext]);

    useEffect(() => {
        AUTH_DEBUG && console.log("AuthContext: ", context);
    }, [context]);

    return <AuthContext.Provider value={{ ...context, setToken, refreshUserOnContext }}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
    return React.useContext(AuthContext);
};
