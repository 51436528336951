import { HttpResponse, HttpStatus, BASE_URL, AUTH_DEBUG } from "../default";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const patchUser = async (user, formData, token) => {
    const url = `${BASE_URL}/users/${user}`
    var errorMessage;
    try {
        const options = {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                jwt: token,
            },
            body: formData,
        }

        const response = await fetch(url, options);
        if (response.ok) {
            const data = await response.json();
            AUTH_DEBUG && console.log("UserAPI::patchUser(): ", data.token);
            return new HttpResponse(HttpStatus.OK, data);
        } else {
            errorMessage = await response.json();
            throw new Error("Error on UserAPI()")
        }
    } catch (error) {
        console.warn(error)
        return new HttpResponse(HttpStatus.ERROR, errorMessage);
    }
}

const getClientSchedules = async (user_id, token, page) => {
    const url = `${BASE_URL}/partner/client/getSchedulesList/${user_id}/?page=${page}`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            jwt: token,
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("UserAPI::getClientSchedules(): ", data);
        return data;
    } else {
        throw new Error("Error on getClientSchedules()");
    }
};

export function useClientSchedulesData(user_id, token, page) {
    const query = useQuery({
        queryKey: [ 'ClientSchedules', user_id, page ],
        queryFn: () => getClientSchedules(user_id, token, page),
        enabled: !!user_id,
        retry: 0,
    });

    return {
        ...query,
        ClientData: query.data,
    };
}

const getClientPartnerPerfil = async (partner_id) => {
    const url = `${BASE_URL}/partner/client/partnerPerfil/${partner_id}/`;

    const options = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    };

    const response = await fetch(url, options);
    if (response.ok) {
        const data = await response.json();
        AUTH_DEBUG && console.log("UserAPI::getClientPartnerPerfil(): ", data);
        return data;
    } else {
        throw new Error("Error on getClientPartnerPerfil()");
    }
};

export function useClientPartnerPerfilData(partner_id) {
    const query = useQuery({
        queryKey: [ 'ClientPartnerPerfil', partner_id ],
        queryFn: () => getClientPartnerPerfil(partner_id),
        enabled: !!partner_id,
        retry: 0,
    });

    return {
        ...query,
        PerfilData: query.data,
    };
}

export const UserAPI = {
    patchUser,
}